import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  Box,
  Snackbar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  MenuItem
} from '@mui/material';
import { Close, ArrowBack, Search, CameraAlt } from '@mui/icons-material';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';

const BreakfastPage = () => {
  const [searchText, setSearchText] = useState('');
  const [foodItems, setFoodItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [unit, setUnit] = useState('100');
  const [totalCalories, setTotalCalories] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const codeReader = new BrowserMultiFormatReader();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem) {
      const baseKcal = selectedItem.kcal;
      const baseUnit = 100;
      if (unit === 'Gramm' || unit === 'ml') {
        setTotalCalories(Math.round((baseKcal / baseUnit) * quantity));
      } else {
        setTotalCalories(Math.round(baseKcal * quantity));
      }
    }
  }, [quantity, unit, selectedItem]);

  const handleSearch = async (query) => {
    setFoodItems([]);
    if (/^\d+$/.test(query)) {
      const response = await fetch(`https://world.openfoodfacts.org/api/v0/product/${query}.json`);
      const data = await response.json();
      if (data.product) {
        setFoodItems([
          {
            product_name: data.product.product_name,
            kcal: Math.round(data.product.nutriments['energy-kcal_100g']),
            unit: data.product.product_quantity_unit === 'ml' ? 'ml' : 'g',
          },
        ]);
      } else {
        setSnackbarMessage('Produkt nicht gefunden');
      }
    } else {
      window.open(`https://world.openfoodfacts.org/cgi/search.pl?search_terms=${query}&search_simple=1&action=process`);
    }
  };

  const handleCameraClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png';
    input.capture = 'environment';
    input.click();
  };

  const handleAddItem = () => {
    if (selectedItem) {
      const token = localStorage.getItem('token');
      fetch('/api/add-calories', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          date: new Date().toISOString().split('T')[0],
          name: selectedItem.product_name,
          mealtime: 'breakfast',
          kcal: totalCalories,
        }),
      }).then((response) => {
        if (response.ok) {
          setSnackbarMessage('Lebensmittel erfolgreich hinzugefügt!');
          setDialogOpen(false);
          setSelectedItem(null);
        } else {
          setSnackbarMessage('Fehler beim Hinzufügen des Lebensmittels.');
        }
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <IconButton onClick={() => navigate('/dashboard')} aria-label="back" size="large" sx={{ fontSize: 24 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" textAlign="center" sx={{ flex: 1 }}>
          Frühstück hinzufügen
        </Typography>
      </Box>

      <Box mt={2} display="flex" alignItems="center">
        <TextField
          fullWidth
          placeholder="Artikel suchen"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <IconButton onClick={() => handleSearch(searchText)} aria-label="search">
          <Search />
        </IconButton>
        <IconButton onClick={handleCameraClick} aria-label="camera">
          <CameraAlt />
        </IconButton>
      </Box>

      {/* Tabs for recent results and custom combinations */}
      <Box mt={2}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)} centered>
          <Tab label="Letzte Ergebnisse" />
          <Tab label="Eigene Kombinationen" />
        </Tabs>
        <Box mt={2}>
          {tabIndex === 0 && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Produkt 1</TableCell>
                  <TableCell>100 kcal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Produkt 2</TableCell>
                  <TableCell>200 kcal</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {tabIndex === 1 && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Eigene Kombination 1</TableCell>
                  <TableCell>300 kcal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eigene Kombination 2</TableCell>
                  <TableCell>150 kcal</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
      </Box>

      <Box mt={2}>
        {foodItems.map((item, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography>
                {item.product_name} - ({item.kcal} kcal / 100{item.unit})
              </Typography>
              <Button onClick={() => { setSelectedItem(item); setDialogOpen(true); }}>
                Hinzufügen
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{selectedItem?.product_name}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              label="Menge"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            />
            <FormControl fullWidth>
              <InputLabel>Einheit</InputLabel>
              <Select value={unit} onChange={(e) => setUnit(e.target.value)}>
                <MenuItem value="100">100 {selectedItem?.unit}</MenuItem>
                <MenuItem value={selectedItem?.unit}>{selectedItem?.unit}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography mt={2}>Gesamtkalorien: {Math.round(totalCalories)} kcal</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Abbrechen</Button>
          <Button onClick={handleAddItem}>Hinzufügen</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!snackbarMessage}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
      />
    </Container>
  );
};

export default BreakfastPage;
