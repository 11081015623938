import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home, Person, Settings } from '@mui/icons-material';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import BreakfastPage from './Foodtracking/BreakfastPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<h1>Startseite</h1>} />
          <Route path="/settings" element={<h1>Einstellungen</h1>} />
          <Route path="/profile" element={<h1>Profil</h1>} />
          {/* Hier binden wir die Dashboard-Komponente ein */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/breakfast" element={<BreakfastPage />} />
        </Routes>
        <BottomNav />
      </div>
    </Router>
  );
}

// Untere Navigationsleiste
const BottomNav = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate('/dashboard');
    if (newValue === 1) navigate('/profile');
    if (newValue === 2) navigate('/settings');
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 16, left: 16, right: 16, borderRadius: '32px'}} elevation={3}>
      <BottomNavigation sx={{ backgroundColor: 'transparent' }} value={value} onChange={handleNavigationChange}>
        <BottomNavigationAction label="Dashboard" icon={<Home />} />
        <BottomNavigationAction label="Profil" icon={<Person />} />
        <BottomNavigationAction label="Einstellungen" icon={<Settings />} />
      </BottomNavigation>
    </Paper>
  );
};

export default App;
