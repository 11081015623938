import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, IconButton, Card, CardContent, Button, Fab, Menu, MenuItem } from '@mui/material';
import { Add, ArrowBack, ArrowForward, Restaurant, LocalCafe, Fastfood, LocalPizza } from '@mui/icons-material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const navigate = useNavigate();
  const [totalCalories, setTotalCalories] = useState(2000);
  const [consumedCalories, setConsumedCalories] = useState(0);
  const [caloriesByMealtime, setCaloriesByMealtime] = useState({
    breakfast: 0,
    lunch: 0,
    dinner: 0,
    snacks: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);

  const formatDateForDB = (date) => date.toISOString().split('T')[0];
  const formatDateForDisplay = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const fetchCalories = async (date) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('/api/get-calories', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, date: formatDateForDB(date) }),
      });

      if (response.ok) {
        const data = await response.json();
        setConsumedCalories(data.total_calories);
      } else {
        console.error('Fehler beim Laden der Kalorien.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCaloriesByMealtime = async (date) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('/api/get-calories-by-mealtime', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, date: formatDateForDB(date) }),
      });

      if (response.ok) {
        const data = await response.json();
        setCaloriesByMealtime(data);
      } else {
        console.error('Fehler beim Laden der Kalorien nach Mahlzeit.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  };

  useEffect(() => {
    fetchCalories(currentDate);
    fetchCaloriesByMealtime(currentDate);
  }, [currentDate]);

  const remainingCalories = totalCalories - consumedCalories;
  const isOverLimit = consumedCalories >= totalCalories;

  const data = {
    labels: ['Konsumiert', 'Übrig'],
    datasets: [
      {
        label: 'Kalorien',
        data: [consumedCalories, remainingCalories > 0 ? remainingCalories : 0],
        backgroundColor: isOverLimit ? ['#FF0000', '#FF0000'] : ['#FFA500', '#008000'],
        hoverBackgroundColor: isOverLimit ? ['#FF0000', '#FF0000'] : ['#FFA500', '#008000'],
        borderWidth: 1,
        cutout: '50%',
        rotation: -90,
        circumference: 180,
      },
    ],
  };

  const handlePreviousDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Container maxWidth="md">
      <Box textAlign="center" my={2}>
        <Typography variant="h4">Dashboard</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
        <IconButton onClick={handlePreviousDay}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{formatDateForDisplay(currentDate)}</Typography>
        <IconButton onClick={handleNextDay}>
          <ArrowForward />
        </IconButton>
      </Box>

      {isLoading ? (
        <Typography>Lade Daten...</Typography>
      ) : (
        <>
          <Card sx={{ mb: 2 }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box textAlign="center">
                <Typography variant="h6">Eingetragen</Typography>
                <Typography>{consumedCalories} kcal</Typography>
              </Box>

              <Box width="40%">
                <Doughnut data={data} options={{ responsive: true, maintainAspectRatio: false }} />
              </Box>

              <Box textAlign="center">
                <Typography variant="h6">Übrig</Typography>
                <Typography>{remainingCalories > 0 ? remainingCalories : 0} kcal</Typography>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ mb: 2 }}>
          <CardContent>
  <Typography variant="h6" sx={{ mb: 2 }}>Kalorien nach Mahlzeiten</Typography>
  <ul
    style={{
      listStyleType: 'none', // Entfernt die Aufzählungspunkte
      padding: 0, // Entfernt den Standard-Innenabstand
      margin: 0, // Entfernt den Standard-Außenabstand
    }}
  >
    <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
      <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
        Frühstück
      </div>
      <div style={{ width: '50%', textAlign: 'left' }}>
        {caloriesByMealtime.breakfast} kcal
      </div>
    </li>
    <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
      <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
        Mittagessen
      </div>
      <div style={{ width: '50%', textAlign: 'left' }}>
        {caloriesByMealtime.lunch} kcal
      </div>
    </li>
    <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
      <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
        Abendessen
      </div>
      <div style={{ width: '50%', textAlign: 'left' }}>
        {caloriesByMealtime.dinner} kcal
      </div>
    </li>
    <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
      <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
        Snacks
      </div>
      <div style={{ width: '50%', textAlign: 'left' }}>
        {caloriesByMealtime.snacks} kcal
      </div>
    </li>
  </ul>
</CardContent>

</Card>

          {/* Floating Action Button */}
<Fab
  color="primary"
  aria-label="add"
  onClick={handleOpenMenu}
  sx={{
    position: 'fixed',
    bottom: 80, // Positioniere den FAB über der Navigationsleiste
    right: 16,
    zIndex: 10, // Sicherstellen, dass er über anderen Elementen liegt
  }}
>
  <Add />
</Fab>

{/* Menü über dem FAB */}
<Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleCloseMenu}
  anchorOrigin={{
    vertical: 'top', // Menü startet oberhalb des FAB
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'bottom', // Menü schwebt über dem FAB
    horizontal: 'center',
  }}
  sx={{
    '& .MuiPaper-root': {
      backgroundColor: 'transparent', // Hintergrund des Menüs transparent machen
      boxShadow: 'none', // Schatten entfernen
      padding: 0, // Entfernt unnötige Abstände
    },
  }}
>
  <MenuItem
    onClick={() => { navigate('/breakfast'); handleCloseMenu(); }}
    sx={{
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      margin: '8px',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    <LocalCafe sx={{ mr: 1 }} /> Frühstück
  </MenuItem>
  <MenuItem
    onClick={() => { navigate('/lunch'); handleCloseMenu(); }}
    sx={{
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      margin: '8px',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    <Restaurant sx={{ mr: 1 }} /> Mittagessen
  </MenuItem>
  <MenuItem
    onClick={() => { navigate('/dinner'); handleCloseMenu(); }}
    sx={{
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      margin: '8px',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    <LocalPizza sx={{ mr: 1 }} /> Abendessen
  </MenuItem>
  <MenuItem
    onClick={() => { navigate('/snacks'); handleCloseMenu(); }}
    sx={{
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      margin: '8px',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }}
  >
    <Fastfood sx={{ mr: 1 }} /> Snacks
  </MenuItem>
</Menu>

        </>
      )}
    </Container>
  );
};

export default Dashboard;
